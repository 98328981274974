import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

// find icons here: https://fontawesome.com/search

// Change the config to fix the flicker
config.mutateApproach = "sync";

import {
  faArchive,
  faArrowLeft,
  faBan,
  faCaretDown,
  faCheck,
  faCheckSquare,
  faClock,
  faDownload,
  faEnvelope,
  faTriangleExclamation,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFileSlash,
  faFileWord,
  faFilm,
  faFilter,
  faGift,
  faGlobe,
  faCircleInfo,
  faLayerGroup,
  faMusic,
  faPaperclip,
  faPencil,
  faPlus,
  faSave,
  faSearch,
  faSortAmountDown,
  faSquare,
  faTags,
  faTimes,
  faTrashCan,
  faTrashUndo,
  faTruck,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faArchive,
  faArrowLeft,
  faBan,
  faCaretDown,
  faCheck,
  faCheckSquare,
  faClock,
  faDownload,
  faEnvelope,
  faTriangleExclamation,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFileSlash,
  faFileWord,
  faFilm,
  faFilter,
  faGift,
  faGlobe,
  faCircleInfo,
  faLayerGroup,
  faMusic,
  faPaperclip,
  faPencil,
  faPlus,
  faSave,
  faSearch,
  faSortAmountDown,
  faSquare,
  faTags,
  faTimes,
  faTrashCan,
  faTrashUndo,
  faTruck,
  faUpload,
);

import {
  faCaretDown as fasCaretDown,
  faCaretUp as fasCaretUp,
  faCheck as fasCheck,
  faSearch as fasSearch,
  faTimes as fasTimes,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  fasCaretDown,
  fasCaretUp,
  fasCheck,
  fasSearch,
  fasTimes,
);

import {
  faArchive as farArchive,
  faArrowLeft as farArrowLeft,
  faCaretDown as farCaretDown,
  faCheck as farCheck,
  faCheckSquare as farCheckSquare,
  faClock as farClock,
  faDownload as farDownload,
  faEnvelope as farEnvelope,
  faTriangleExclamation as farTriangleExclamation,
  faFileLines as farFileLines,
  faFileImage as farFileImage,
  faFilePdf as farFilePdf,
  faFileWord as farFileWord,
  faFilm as farFilm,
  faFilter as farFilter,
  faGift as farGift,
  faGlobe as farGlobe,
  faCircleInfo as farCircleInfo,
  faMusic as farMusic,
  faPencil as farPencil,
  faPlus as farPlus,
  faSave as farSave,
  faSearch as farSearch,
  faSortAmountDown as farSortAmountDown,
  faSquare as farSquare,
  faSquareCheck as farSquareCheck,
  faTags as farTags,
  faTimes as farTimes,
  faTrashCan as farTrashCan,
  faTruck as farTruck,
  faUpload as farUpload,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  farArchive,
  farArrowLeft,
  farCaretDown,
  farCheck,
  farCheckSquare,
  farClock,
  farDownload,
  farEnvelope,
  farTriangleExclamation,
  farFileLines,
  farFileImage,
  farFilePdf,
  farFileWord,
  farFilm,
  farFilter,
  farGift,
  farGlobe,
  farCircleInfo,
  farMusic,
  farPencil,
  farPlus,
  farSave,
  farSearch,
  farSortAmountDown,
  farSquare,
  farSquareCheck,
  farTags,
  farTimes,
  farTrashCan,
  farTruck,
  farUpload,
);

import {
  faFacebook as fabFacebook,
  faFacebookF as fabFacebookF,
  faInstagram as fabInstagram,
  faTwitter as fabTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  fabFacebook,
  fabFacebookF,
  fabInstagram,
  fabTwitter,
);

dom.watch();
